const hamburgerTrigger = document.querySelector(".js-hamburgerTrigger");
const headerNav = document.querySelector(".js-headerNav");
const headerClose = document.querySelector(".js-headerClose");
const headerAc = document.querySelectorAll(".js-headerAc");

export function hamburger() {
	// ハンバーガー表示
	hamburgerOpen();

	// ハンバーガー非表示
	hamburgerClose();

	// ハンバーガー内アコーディオンtoggle
	hamburgerAc();
}

function hamburgerOpen() {
	hamburgerTrigger.addEventListener("click", () => {
		document.querySelector("body").classList.add("is-fixed");
		headerNav.classList.add("is-view");
	});
}

function hamburgerClose() {
	headerClose.addEventListener("click", () => {
		document.querySelector("body").classList.remove("is-fixed");
		headerNav.classList.remove("is-view");
	});
}

function hamburgerAc() {
	const headerAcNode = Array.prototype.slice.call(headerAc, 0);

	headerAcNode.forEach((targetBox) => {
		targetBox.addEventListener("click", () => {
			if (targetBox.nextElementSibling.classList.contains("is-hide")) {
				targetBox.classList.add("is-active"); // 矢印状態変化
				targetBox.nextElementSibling.classList.remove("is-hide"); // アコーディオン表示
			} else {
				targetBox.classList.remove("is-active"); // 矢印状態変化
				targetBox.nextElementSibling.classList.add("is-hide"); // アコーディオン非表示
			}
		});
	});
}
