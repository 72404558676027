import { hamburger } from "./modules/hamburger";
import { modal } from "./modules/modal";
import { pageTop } from "./modules/pageTop";
import { smooth } from "./modules/smooth";

hamburger();
modal();
pageTop();

const scrollTop = new SmoothScroll('a[href*="#"]', {
	speed: 600,
});

window.addEventListener("load", () => {
	smooth();
});
