// 共通項目
// モーダルを開きたい時に押す要素にjs-modalTriggerを設定
// data-modalTypeの内容によって、表示するmodalを選択する
const modalTrigger = document.querySelectorAll(".js-modalTrigger");
const modalOverlay = document.querySelector(".js-modalOverlay");
const modalClose = document.querySelectorAll(".js-modalClose");
const modalBox = document.querySelectorAll(".js-modalBox"); // 各モーダルに共通で操作を行いたい時に使用する

// 独自のモーダル
const modalShopping = document.querySelector(".js-modalShopping"); // headerのshoppingモーダル

export function modal() {
	modalActionOpen();
	modalActionClose();
}

function modalActionOpen() {
	const modalTriggerNode = Array.prototype.slice.call(modalTrigger, 0);

	modalTriggerNode.forEach((targetBox) => {
		targetBox.addEventListener("click", () => {
			// js-modalTriggerのdata-modalTypeを取得する
			const modalType = targetBox.getAttribute("data-modalType");

			// overlayを表示
			modalOverlay.classList.add("is-view");
			document.querySelector("body").classList.add("is-fixed");

			// data-modalTypeによって、表示するモーダルを選択
			switch (modalType) {
				case "shopping":
					modalShopping.classList.add("is-view");
					break;
			}
		});
	});
}

function modalActionClose() {
	const modalCloseNode = Array.prototype.slice.call(modalClose, 0);

	modalCloseNode.forEach((targetBox) => {
		targetBox.addEventListener("click", () => {
			modalOverlay.classList.remove("is-view");
			document.querySelector("body").classList.remove("is-fixed");

			for (let i = 0; i < modalBox.length; i++) {
				modalBox[i].classList.remove("is-view");
			}
		});
	});

	modalOverlay.addEventListener("click", () => {
		modalOverlay.classList.remove("is-view");
		document.querySelector("body").classList.remove("is-fixed");

		for (let i = 0; i < modalBox.length; i++) {
			modalBox[i].classList.remove("is-view");
		}
	});
}
